var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',{attrs:{"title":"Custom scoped slots","modalid":"modal-3","modaltitle":"Custom scoped slots"},scopedSlots:_vm._u([{key:"highlight",fn:function(){return [_c('pre',{staticClass:"mb-0"},[_vm._v("        "),_c('code',{staticClass:"javascript"},[_vm._v("\n<b-button variant=\"primary\" @click=\"$bvModal.show('modal-scoped')\">Open Modal</b-button>\n\n<b-modal id=\"modal-scoped\">\n  <template #modal-header=\"{ close }\">\n    <!-- Emulate built in modal header close button action -->\n    <b-button size=\"sm\" variant=\"outline-danger\" @click=\"close()\">\n      Close Modal\n    </b-button>\n    <h5>Modal Header</h5>\n  </template>\n\n  <template #default=\"{ hide }\">\n    <p>Modal Body with button</p>\n    <b-button @click=\"hide()\">Hide Modal</b-button>\n  </template>\n\n  <template #modal-footer=\"{ ok, cancel, hide }\">\n    <b>Custom Footer</b>\n    <!-- Emulate built in modal footer ok and cancel button actions -->\n    <b-button size=\"sm\" variant=\"success\" @click=\"ok()\"> OK </b-button>\n    <b-button size=\"sm\" variant=\"danger\" @click=\"cancel()\">\n      Cancel\n    </b-button>\n    <!-- Button with custom close trigger value -->\n    <b-button\n      size=\"sm\"\n      variant=\"outline-secondary\"\n      @click=\"hide('forget')\"\n    >\n      Forget it\n    </b-button>\n  </template>\n</b-modal>\n        ")]),_vm._v("\n      ")])]},proxy:true},{key:"comcode",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show('modal-scoped')}}},[_vm._v("Open Modal")]),_c('b-modal',{attrs:{"id":"modal-scoped"},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" Close Modal ")]),_c('h5',[_vm._v("Modal Header")])]}},{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('p',[_vm._v("Modal Body with button")]),_c('b-button',{on:{"click":function($event){return hide()}}},[_vm._v("Hide Modal")])]}},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
var hide = ref.hide;
return [_c('b',[_vm._v("Custom Footer")]),_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return ok()}}},[_vm._v(" OK ")]),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return hide('forget')}}},[_vm._v(" Forget it ")])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }